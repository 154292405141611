"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWithoutJsonParse = exports.setWithoutJsonEncoding = exports.remove = exports.set = exports.get = exports.isAvailable = void 0;
const isAvailable = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    // Using try/catch to avoid "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document."
    try {
        if (typeof localStorage === 'object') {
          return true;
        }
    }
    catch (err) {
    }

    return false;
};
exports.isAvailable = isAvailable;
const get = (key) => {
    if ((0, exports.isAvailable)()) {
        try {
            return JSON.parse(localStorage.getItem(key) || '{}');
        }
        catch (err) {
            // console.error('Error Parse JSON from Local Storage', { err })
            return {};
        }
    }
    else {
        return {};
    }
};
exports.get = get;
const set = (key, data) => (0, exports.isAvailable)() && localStorage.setItem(key, JSON.stringify(data));
exports.set = set;
const remove = (key) => (0, exports.isAvailable)() && localStorage.removeItem(key);
exports.remove = remove;
const setWithoutJsonEncoding = (key, data) => (0, exports.isAvailable)() && localStorage.setItem(key, data);
exports.setWithoutJsonEncoding = setWithoutJsonEncoding;
const getWithoutJsonParse = (key) => {
    if ((0, exports.isAvailable)()) {
        try {
            return localStorage.getItem(key) || '';
        }
        catch (err) {
            // console.error('Error Parse JSON from Local Storage', { err })
            return {};
        }
    }
    else {
        return {};
    }
};
exports.getWithoutJsonParse = getWithoutJsonParse;
